import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import Div from "../Div";
import DropDown from "./DropDown";

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  return (
    <>
      <header
        className={`_site_header _style1 text-uppercase ${
          variant ? variant : ""
        } _sticky_header ${isSticky ? "_sticky_header_active" : ""}`}
      >
        <Div className="_main_header">
          <Div className="container">
            <Div className="_main_header_in">
              <Div className="_main_header_left">
                <Link className="_site_branding" to="/">
                  <img
                    src="/images/tse_logo.png"
                    alt="The Social Eye's logo"
                    width={200}
                  />
                </Link>
              </Div>
              <Div className="_main_header_center">
                <Div className="_nav _primary_font _medium">
                  <ul
                    className="_nav_list"
                    style={{ display: `${mobileToggle ? "block" : "none"}` }}
                  >
                    <li>
                      <NavLink
                        to="about"
                        onClick={() => setMobileToggle(false)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="menu-item-has-children">
                      <Link>Resources</Link>
                      <DropDown>
                        <ul>
                          <li>
                            <Link
                              to="https://drive.google.com/file/d/1Zy8laER4tiUXFSM7qB10maVQ7MCuqWfx/view"
                              onClick={() => setMobileToggle(false)}
                              target="_blank"
                            >
                              Media deck
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://drive.google.com/file/d/1p7xFviQ_F2UKOLDPYHaOg_FRie3gOwET/view?usp=sharing"
                              onClick={() => setMobileToggle(false)}
                              target="_blank"
                            >
                              Case study
                            </Link>
                          </li>
                        </ul>
                      </DropDown>
                    </li>

                   
                    <li>
                      <NavLink
                        to="/contact"
                        onClick={() => setMobileToggle(false)}
                      >
                        Contact
                      </NavLink>
                    </li>
                    <li className="menu-item-has-children">
                      <Link>Get started</Link>
                      <DropDown>
                        <ul>
                          <li>
                            <Link
                              to="https://thesocialeye.offer18.com/m/signup_self_aff?r=&am="
                              onClick={() => setMobileToggle(false)}
                              target="_blank"
                            >
                              SIGNUP AS AFFILIATE
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://thesocialeye.offer18.com/m/signup_self_adv?r=&am="
                              onClick={() => setMobileToggle(false)}
                              target="_blank"
                            >
                              SIGNUP AS ADVERTISER
                            </Link>
                          </li>
                        </ul>
                      </DropDown>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "_munu_toggle _toggle_active"
                        : "_munu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>
    </>
  );
}
