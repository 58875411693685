import React from 'react'
import parse from 'html-react-parser';
import './cta.scss'
import Button from '../Button';
import Div from '../Div';
import Spacing from '../Spacing';

export default function Cta({title, btnText, btnLink, bgSrc, variant}) {
  return (
    <Div className={`_cta _style1 _bg text-center _shape_wrap_1 _position_1 ${variant?variant:''}`} style={{backgroundImage: `url(${bgSrc})`}}>
      <Div className="_shape_1" />
      <Div className="_shape_1" />
      <Div className="_shape_1" />
      <Div className="_cta_in">
        <h2 className="_cta_title _semi_bold _m0 back-color">{parse(title)}</h2>
        {btnText && (
          <>
            <Spacing lg='70' md='30' />
            <Button 
              btnLink={btnLink}
              btnText={btnText}
            />
          </>
        )}
      </Div>
    </Div>
  )
}
