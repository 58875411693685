import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Div from "../Div";

export default function SocialWidget() {
  return (
    <Div className="_social_btns _style1">
      <Link
        to="https://www.linkedin.com/company/thesocialeyein"
        className="_center"
        target="_blank"
      >
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>

      <Link
        to="https://www.facebook.com/thesocialeyein"
        className="_center"
        target="_blank"
      >
        <Icon icon="fa6-brands:facebook" />
      </Link>
      <Link
        to="https://www.instagram.com/thesocialeyein/"
        className="_center"
        target="_blank"
      >
        <Icon icon="fa6-brands:instagram" />
      </Link>
      <Link
        to="https://twitter.com/thesocialeyein"
        className="_center"
        target="_blank"
      >
        <Icon icon="fa6-brands:twitter" />
      </Link>
    </Div>
  );
}
