import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import {useRef } from "react";
import emailjs from "@emailjs/browser";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import { useState } from "react";
import { pageTitle } from "../../helper";

export default function ContactPage() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert("Message sent successfully 🤘");

          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  pageTitle("Contact");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Want to unlock the power of advertising?"
              subtitle="Get in touch. Time to forge new partnership!"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form className="row" ref={form} onSubmit={sendEmail}>
              <Div className="col-sm-6">
                <label className="_primary_color">Name*</label>
                <input
                  type="text"
                  name="userName"
                  className="_form_field"
                  // value="Minimum 3 character"
                  required
                  // minlength="3"
                  maxlength="30"
                  // size="32"
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="_primary_color">Phone*</label>
                <input
                  type="text"
                  name="userPhone"
                  className="_form_field"
                  minlength="10"
                  required
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="_primary_color">Email*</label>
                <input
                  type="email"
                  name="userEmail"
                  className="_form_field"
                  size="30"
                  required
                />
                <Spacing lg="20" md="20" />
              </Div>

              <Div className="col-sm-12">
                <label className="_primary_color">Subject*</label>
                <input
                  type="text"
                  name="userSubject"
                  className="_form_field"
                  required
                />
                <Spacing lg="20" md="20" />
              </Div>

              <Div className="col-sm-12">
                <label className="_primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="_form_field"
                  name="userMessage"
                  required
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="_btn _style1" type="submit">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="80" md="80" />
      <Div className="container">
      <Div className="_google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d465.24155079020335!2d80.99064471310906!3d26.76261647367024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be5e84b1b3cdb%3A0x90cc15e2519c2f8c!2sSaryu%20Enclave%20Avadh%20Vihar%20Yojna!5e0!3m2!1sen!2sin!4v1684130862606!5m2!1sen!2sin"
          allowFullScreen
          title="Google Map"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        </Div>
      </Div>
      {/* <Spacing lg="50" md="40" /> */}
    </>
  );
}
