import React, { useEffect } from "react";

import FunFact from "../FunFact";
import Hero from "../Hero";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import MovingText2 from "../MovingText/MovingText2";
import { pageTitle } from "../../helper";
import Spacing from "../Spacing";
import Cta from "../Cta";
import { LogoList1, LogoList2 } from "../LogoList";
import Marquee from "react-fast-marquee";

export default function Home() {
  pageTitle("The Social Eye - The Hyperlocal Performance Marketing Agency");

  const heroSocialLinks = [
    {
      name: "Linkedin",
      links: "https://www.linkedin.com/company/thesocialeyein",
    },
    {
      name: "Facebook",
      links: "https://www.facebook.com/thesocialeyein",
    },
  ];

  const serviceData1 = [
    {
      title: "Advertising",
    },
    {
      title: "Performance Marketing",
    },
    {
      title: "Branding",
    },
  ];
  const serviceData2 = [
    {
      title: "Full Stack Development",
    },
    {
      title: "SMS & Email Marketing",
    },
    {
      title: "App Development",
    },
  ];

  // FunFact Data
  const ServicesData = [
    {
      title: "Global Clients",
      factNumber: "70",
    },
    {
      title: "Ads",
      factNumber: "50K",
    },
    {
      title: "ROI",
      factNumber: "95%",
    },
    {
      title: "Projects",
      factNumber: "175",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      {/* <Div ref={parallaxRef}> */}

      <Hero
        title={`Namaste!`}
        subtitle="We are hyperlocal performance marketing and advertising agency based out of Lucknow"
        btnText="Say hello!"
        btnLink="/contact"
        scrollDownId="#service"
        bgImageUrl="/images/hero_bg.webp"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        phoneNumber="+91 83170 00340"
        email="prashant@thesocialeye.co.in"
      />

      {/* End Hero Section */}

      {/* Start FunFact Section */}

      <Div className="container">
        <FunFact
          variant="_type1"
          title="Excellence at its best"
          subtitle=""
          data={ServicesData}
        />
      </Div>

      {/* End FunFact Section */}

      {/* Start Service Section */}
      {/* <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
                btnText="See All Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 _hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Performance Marketing"
                    link="/service/service-details"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 _hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Full Stack Development"
                    link="/service/service-details"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Hyperlocal Advertising"
                    link="/service/service-details"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 _hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Email & SMS Marketing"
                    link="/service/service-details"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 _hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div> */}

      {/* End Service Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="OUR TOP SERVICES"
          subtitle="We transform brands
          and businesses through the power of creativity"
          variant="_style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div>
      {/* End Services Section */}

      {/* Start Moving Text Section */}
      <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}

      {/* Start MovingText Section */}

      {/* <CircleBackground color="#fff" className="animate-spin-slower"/> */}
      <Spacing lg="125" md="70" />
      <Div className="container">
        <SectionHeading
          title="TRUSTED BY"
          subtitle="Once our partner, forever our partner"
          variant="_style1 text-center"
        />

        {/* <MovingText text="Hyperlocal | Performance | Excellence" /> */}
        {/* <Spacing lg="105" md="70" /> */}
        {/* End MovingText Section */}

        {/* Start LogoList Section */}

        <Marquee>
          <LogoList1 />
        </Marquee>
        <Marquee direction="right">
          <LogoList2 />
        </Marquee>
       
      </Div>

      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Offering smarter advertising solutions for smarter people"
          btnText="Write to us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpg"
        />
      </Div>
      <Spacing lg="50" md="30" />
      {/* End CTA Section */}
      {/* </Div> */}
    </>
  );
}
