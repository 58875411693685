import React from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import SectionHeading from '../SectionHeading';

export default function ErrorPage() {
  pageTitle('404: Not found');
  return (
    <Div
      className="_page_heading _style1 _center text-center _bg _error_page"
      style={{ backgroundImage: 'url("/images/404.jpg")' }}
    >
      <Div className="container">
        <SectionHeading
          title="You didn’t break the internet, but we can’t find what you are looking for."
          subtitle="404: NOT FOUND"
          btnText="Back To Home"
          btnLink="/"
          variant="_style1 text-center"
        />
        </Div>
    </Div>
  );
}
