import React from "react";
import parse from "html-react-parser";
import "./hero.scss";
import Button from "../Button";
import Div from "../Div";
import Spacing from "../Spacing";
import VerticalLinks from "../VerticalLinks";

export default function Hero({
  title,
  btnText,
  btnLink,

  heroSocialLinks,
  bgImageUrl,
  socialLinksHeading,

  phoneNumber,
  email,
}) {
  return (
    <>
      <Div
        className="_hero _style1 _bg _bg-hover _shape_wrap_1"
        style={{ backgroundImage: `url(${bgImageUrl})` }}
      >
        <Div className="container">
          {/* <Div className="_hero_text"> */}
            <h1 className="_hero_title rainbow">{parse(title)}</h1>
          {/* </Div> */}
          {/* <Spacing /> */}

          <Div className=" _hero_subtitle justify-content-center">
            <span className="hover-underline-animation">
              We are Hyperlocal Performance Marketing and Advertising
              agency based out of Lucknow
            </span>
          </Div>

          <br />
          <Div className="_hero_info">
            
              <Button btnLink={btnLink} btnText={btnText} />
              {/* <p className="_down_btn"></p> */}
            
          </Div>
        </Div>

{/* <Div className="container">
        <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
        {(phoneNumber || email) && (
          <Div className="_hero_social_wrap _left_side _primary_font _primary_color">
            <ul className="_hero_social_links">
              {email && (
                <li>
                  <span>{email}</span>
                </li>
              )}
              {phoneNumber && (
                <li>
                  <span>{phoneNumber}</span>
                </li>
              )}
            </ul>
          </Div>
        )}
      </Div> */}
      </Div>
    </>
  );
}
