import "./preloader.scss";
import Div from "../Div";

const PreLoader = () => {
  return (
    <>
      {/* Preloader */}
      <Div className="preloader">
        {/* <Div className=" full-width"> */}
          <Div>
            <Div className="spinner-logo">
              <img src="/images/spinner.png" alt="The Social Eye" />
              {/* <img src="/images/p.gif" alt="The Social Eye" /> */}
              <Div className="spinner-dot" />
              <Div className="spinner spinner-line" />
            </Div>
          </Div>
        </Div>
      {/* </Div> */}
    </>
  );
};
export default PreLoader;
