import React from "react";
import Div from "../Div";
import "./logolist.scss";

export function LogoList1() {
  const partnerLogos = [
    {
      src: "/images/amazon.png",
      alt: "Amazon",
    },
    {
      src: "/images/bigul.png",
      alt: "Bigul",
    },

    {
      src: "/images/casumo.png",
      alt: "Casumo",
    },
    {
      src: "/images/classplus.png",
      alt: "Classplus",
    },
    {
      src: "/images/cluboxygen.png",
      alt: "Cluboxygen",
    },
    {
      src: "/images/dream11.png",
      alt: "Dream11",
    },
    {
      src: "/images/eureka.png",
      alt: "Eureka",
    },
    {
      src: "/images/happilo.png",
      alt: "Happilo",
    },
    {
      src: "/images/hdfc.png",
      alt: "HDFC",
    },
    {
      src: "/images/kidzee.png",
      alt: "Kidzee",
    },
  ];
  return (
    <Div className="_partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index) => (
        <div className="_partner_logo" key={index}>
          <img src={partnerLogo.src} alt={partnerLogo.alt} />
        </div>
      ))}
    </Div>
  );
}

export function LogoList2() {
  const partnerLogos = [
    {
      src: "/images/kukufm.png",
      alt: "Kuku FM",
    },
    {
      src: "/images/leonbet.png",
      alt: "Leon Bet",
    },
    {
      src: "/images/mstock.png",
      alt: "Mstock",
    },
    {
      src: "/images/rbl.png",
      alt: "RBL Bank",
    },
    {
      src: "/images/sharekhan.png",
      alt: "Share Khan",
    },
    {
      src: "/images/stashfin.png",
      alt: "Stashfin",
    },
    // {
    //   src: "/images/stockydodo.png",
    //   alt: "Stockydodo",
    // },
    {
      src: "/images/yaari.png",
      alt: "Yaari",
    },
    {
      src: "/images/zivame.png",
      alt: "Zivame",
    },
  ];
  return (
    <Div className="_partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index) => (
        <div className="_partner_logo" key={index}>
          <img src={partnerLogo.src} alt={partnerLogo.alt} />
        </div>
      ))}
    </Div>
  );
}
