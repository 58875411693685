import React from 'react'
import Div from '../Div'
import './funfact.scss'

export default function FunFact({variant, title, subtitle, data}) {
  return (
    <Div className={variant ? `_funfact_wrap ${variant}`: '_funfact_wrap'}>
      <Div className="_funfact_shape"  style={{backgroundImage: 'url(./images/funfact_shape_bg.svg)'}} />
      <Div className="_funfact_left">
        <Div className="_funfact_heading">
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </Div>
      </Div>
      <Div className="_funfact_right">
        <Div className="_funfacts">
        {data.map((item, index) => (
          <Div className="_funfact _style1" key={index}>
            <Div className="_funfact_number _primary_font _semi_bold _primary_color"><span/>{item.factNumber}</Div>
            <Div className="_funfact_text">
              <span className="_accent_color">+</span>
              <p>{item.title}</p>
            </Div>
          </Div>
          ))}
        </Div>
      </Div>
    </Div>
  )
}
