import React from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'
import './card.scss'

export default function Card({title, link, src, alt}) {
  return (
    <Link to={link} className="_card _style1">
      <>
        <img src={src} alt={alt} />
        <Div className="_card_overlay" />
        <Div className="_card_info">
          <span className=" _hover_layer3 _accent_bg" />
          <h2 className="_card_title">{title}</h2>
        </Div>
      </>
    </Link>
  )
}
