import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/Pages/AboutPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import ServicesPage from "./components/Pages/ServicesPage";
import Privacy from "./components/Pages/Privacy";
import Layout from "./components/Layout";
import PreLoader from "./components/PreLoader";
import { useEffect, useState } from "react";

function App() {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);
  return (
    <>
      {loader && <PreLoader />}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
