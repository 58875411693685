import React from "react";
import { Icon } from "@iconify/react";

export default function ContactInfoWidget({ withIcon, title }) {
  return (
    <>
      {title && <h2 className="_widget_title">{title}</h2>}
      <ul className="_menu_widget _style1 _mp0">
        <li>
          {withIcon ? (
            <span className="_accent_color">
              <Icon icon="ic:baseline-local-phone" />
            </span>
          ) : (
            " "
          )}
          &nbsp;&nbsp;&nbsp;<a href="tel:+91-83170-00340">+91 83170 00340</a>
        </li>
        <li>
          {withIcon ? (
            <span className="_accent_color">
              <Icon icon="ic:round-email" />
            </span>
          ) : (
            " "
          )}
          &nbsp;&nbsp;&nbsp;
          <a href="mailto:prashant@thesocialeye.co.in" target="_blank" rel="noreferrer">
            prashant@thesocialeye.co.in
          </a>
        </li>
        <li>
          {withIcon ? (
            <span className="_accent_color">
              <Icon icon="mdi:map-marker" />
            </span>
          ) : (
            " "
          )}
          &nbsp;&nbsp;&nbsp;E2, Saryu Enclave, Avadh Vihar Yojana, Lucknow, Uttar
          Pradesh - 226002
        </li>
      </ul>
    </>
  );
}
